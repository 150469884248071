import React from 'react';
import PropTypes from 'prop-types';
import deepEqual from 'react-fast-compare';
import axios from 'axios';
import { Document, Page } from 'react-pdf';
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';
import { connect } from 'react-redux';

import { isArrayValidAndNotEmpty, isImageType } from '../../constants/CommonUtil';
import API from '../../constants/api';
import AutoRotatingCarousel from '../carousel/AutoRotatingCarousel';
import PrintPDF from '../../containers/RegistrationAppComponents/PrintHTML/PrintPDF';
import PDFViewer from '../PDFViewer/PDFViewer';
import { getStringFromObject } from '../../constants/lodashUtils';


class ImageGallery extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            fileTypes: {},
            numPages: 0,
            docUrl: '',
            printLabel: false,
            fileType: '',
        };
    }
    componentDidMount() {
        this.fetchFileTypes();
    }
    componentWillReceiveProps(nextProps) {
        if (!deepEqual(nextProps.images, this.props.images)) {
            this.fetchFileTypes(nextProps);
        }
    }

    onDocumentLoadSuccess =(numPages) => {
        console.log('nouber fo pages', numPages);
        this.setState({
            numPages: numPages.numPages,
        });
    };
    getDocUrl = (uploadedPrintDocument) => {
        const {
            downloadAPI,
            downloadPDFAPI,
        } = this.props;
        if (uploadedPrintDocument) {
            const key = getStringFromObject('key', uploadedPrintDocument);
            const value = getStringFromObject('value', uploadedPrintDocument);
            if ((key && key.endsWith('.pdf')) || (value && value.endsWith('.pdf'))) {
                return `${downloadPDFAPI}${uploadedPrintDocument.key}`;
            }
            return `${downloadAPI}${uploadedPrintDocument.key}`;
        }
        return '';
    };

    handleDocumentPrint = (index) => {
        const { images } = this.props;
        const {
            fileTypes,
        } = this.state;
        const document = images[index];
        const docUrl = this.getDocUrl(document);
        const fileType = fileTypes[getStringFromObject('key', document)];
        const type = isImageType(fileType) ? 'image' : 'pdf';
        console.log('docUrl print ', docUrl);
        if (docUrl) {
            this.setState({
                docUrl,
                printLabel: !this.state.printLabel,
                fileType: type,
            });
        }
    };

    fetchFileTypes = async (props = this.props) => {
        const {
            images,
        } = props;
        console.log('asd-aid-as0diad', images);
        if (isArrayValidAndNotEmpty(images)) {
            const join = images.map(anImage => getStringFromObject('key', anImage)).join(',');
            const response =
                await axios.get(`${API.FILE.GET_FILE_TYPES}${join}`);
            const fileTypes = response.data;
            this.setState({
                fileTypes,
            });
        }
    };

    extractImages = arr => isArrayValidAndNotEmpty(arr) && arr.map((anImageKV) => {
        const src = this.getDocUrl(anImageKV);
        console.log('tthisn s', src);
        const {
            fileTypes,
        } = this.state;
        const {
            pdfRenderer,
        } = this.props;
        const fileType = fileTypes[getStringFromObject('key', anImageKV)];
        console.log('asdas09das-d0ass', anImageKV, src, fileType);
        return isImageType(fileType) ?
            <TransformWrapper>
                <TransformComponent>
                    <img
                        src={src}
                        alt="nothing"
                        style={{
                            maxWidth: '600px', maxHeight: '1200px',
                        }}
                    />
                </TransformComponent>
            </TransformWrapper>
            :
            <React.Fragment>
                {
                    pdfRenderer === 'react-pdf' ? (
                        <div>
                            <Document
                                file={src}
                                onLoadSuccess={this.onDocumentLoadSuccess}
                            >
                                {Array.from(
                                    new Array(this.state.numPages),
                                    (el, index) => (
                                        <Page
                                            test-id={`page_${index + 1}`}
                                            key={`page_${index + 1}`}
                                            pageNumber={index + 1}
                                        />
                                    ),
                                )}
                            </Document>
                        </div>
                    ) : (
                        <PDFViewer key={src} src={src} />
                    )
                }
            </React.Fragment>;
    });

    handleDocumentDownload = (index) => {
        const { images } = this.props;
        const document = images[index];
        const docUrl = this.getDocUrl(document);
        if (docUrl) {
            window.open(docUrl, '_blank');
        }
    };

    render() {
        const {
            images,
            onClose,
            clickedDocument,
            onDownloadAllClick,
        } = this.props;
        const imagesExtracted = this.extractImages(images);
        console.log('asdas-0ui90adsjopa', imagesExtracted);
        /*  return (
            <Gallery
                images={imagesExtracted}
                isOpen
                showLightboxThumbnails
                lightboxWillClose={onClose}
                currentImage={clickedDocument}
                onDownloadAllClick={onDownloadAllClick}
            />
        );  */
        console.log(clickedDocument, onClose, onDownloadAllClick);
        return (
            <div>
                <AutoRotatingCarousel
                    open
                    onClose={onClose}
                    autoplay={false}
                    style={{ position: 'absolute' }}
                    index={clickedDocument}
                    ModalProps
                    downloadDocument={this.handleDocumentDownload}
                    printDocument={this.handleDocumentPrint}
                >
                    {imagesExtracted}
                </AutoRotatingCarousel>
                <PrintPDF
                    print={this.state.printLabel}
                    itemsToPrint={[
                        {
                            name: 'report Print',
                            type: this.state.fileType,
                            url: this.state.docUrl,
                        },
                    ]}
                />
            </div>
        );
    }
}

ImageGallery.propTypes = {
    images: PropTypes.array,
    onClose: PropTypes.func,
    onDownloadAllClick: PropTypes.func,
    clickedDocument: PropTypes.number,
    downloadAPI: PropTypes.string.isRequired,
    downloadPDFAPI: PropTypes.string.isRequired,
    pdfRenderer: PropTypes.string,
};

ImageGallery.defaultProps = {
    images: [],
    onClose: () => {},
    onDownloadAllClick: () => {},
    clickedDocument: 0,
    pdfRenderer: '',
};

const mapStateToProps = state => ({
    pdfRenderer: getStringFromObject('appConfiguration.pdfRenderer', state),
});

export default connect(mapStateToProps)(ImageGallery);
